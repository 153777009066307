/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var $slider;

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'tax_property_type': {
      init: function() {
        if (window.innerWidth > 768) {
          this.initMapTooltip();
        }
      },
      initMapTooltip: function() {
        $('#Map a').hover(function(){
            // Hover over code
            var title = $(this).attr('title');
            $(this).data('tipText', title).removeAttr('title');
            $('<p class="ertooltip"></p>')
            .text(title)
            .appendTo('body').show();
        }, function() {
            // Hover out code
            $(this).attr('title', $(this).data('tipText'));
            $('.ertooltip').remove();
        }).mousemove(function(e) {
            var mousex = e.pageX + 20; //Get X coordinates
            var mousey = e.pageY + 10; //Get Y coordinates
            $('.ertooltip')
            .css({ top: mousey, left: mousex });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  var ST = {
    init: function() {
      this.initSelect();
      this.initPropertySearch();
      this.initDatatable();
      this.initHomeSlider();
      this.initHeroSlider();
    },
    initSelect: function() {
      var $propertySearch = $('.search-header select').select2({
        placeholder: "SELECT A PROPERTY",
      });
    },
    initPropertySearch: function() {
      $('.search-header select').on('change', function(e){
        var url = $(this).val();
        if (url) {
          window.location = $(this).val();
        }
      });
    },
    initDatatable: function() {
      var $table = $('table.sortable').DataTable({
  			order: [[3, "asc"], [2, "asc"]],
        dom: 'tp',
        responsive: true,
        pageLength: 10
  		});
      this.dataTableFilter($table);

      $(window).resize(function() {
        clearTimeout(window.refresh_size);
        window.refresh_size = setTimeout(function() { $('table.sortable').resize(); }, 250);
      });
    },
    initHomeSlider: function() {
      if ( !$('body').hasClass('home') ) {return false;}
      $details = $('.fs-slider-detail');
      var $slider = $('.fs-slider').bxSlider({
        auto: true,
        pause: 5000,
        slideMargin: 0,
        easing: 'ease-in-out',
        speed: 1000,
        pagerCustom: '.fs-pager'
      });
      $('.fs-play-pause').on('click', function(e){
        if ( $(this).hasClass('playing') ) {
          $slider.stopAuto();
          $(this).removeClass('playing');
        }
        else {
          $slider.startAuto();
          $(this).addClass('playing');
        }
      });
      $(".fs-pager a").click(function () {
          $slider.stopAuto();
          $slider.startAuto();
      });
    },
    initHeroSlider: function() {
      if ( $('body').hasClass('home') ) {return false;}
      var $gallery = $('#photo-gallery ul');
      var $slider = $gallery.bxSlider({
        auto: true,
        mode: 'fade',
        pager: true,
        pagerCustom: '.fs-pager'
      });
      $('.fs-play-pause').on('click', function(e){
        if ( $(this).hasClass('playing') ) {
          $slider.stopAuto();
          $(this).removeClass('playing');
        }
        else {
          $slider.startAuto();
          $(this).addClass('playing');
        }
      });
    },
    dataTableFilter: function($table) {
      var $stateFilter = $('.property-search-filters select.state'),
          $cityFilter = $('.property-search-filters select.city'),
          $brokerFilter = $('.property-search-filters select.broker'),
          stateCol = $table.column(3),
          cityCol = $table.column(2),
          brokerCol = $table.column(1);

      $stateFilter.on('change', function(e){
        var val = $.fn.dataTable.util.escapeRegex($(this).val());
        if (!val) {
          $table.search( '' ).columns().search( '' ).draw();
           return;
        }
        $brokerFilter.val('');
        stateCol.search(val, true, true).draw();
        brokerCol.search('', true, true).draw();
        if (typeof(render_map) === "function") {
          render_map();
        }
      });

      $cityFilter.on('change', function(e){
        var val = $.fn.dataTable.util.escapeRegex($(this).val());
        $brokerFilter.val('');
        cityCol.search(val, true, true).draw();
        brokerCol.search('', true, true).draw();
      });

      $brokerFilter.on('change', function(e){
        var val = $.fn.dataTable.util.escapeRegex($(this).val());
        $stateFilter.val('');
        $cityFilter.val('');
        stateCol.search('', true, true).draw();
        cityCol.search('', true, true).draw();
        brokerCol.search(val, true, true).draw();
      });
    }
  };

  // Load Events
  $(document).ready(function(){
    UTIL.loadEvents();
    ST.init();
  });

})(jQuery); // Fully reference jQuery after this point.
